import { CustomerGatewayHealthResponseModel } from '@swagger-http';

import { EnergyActionTypes, EnergyFlowActionTypes } from '@store/enums';
import {
    EnergyState,
    EnergyAction,
    LiveDataAction,
    EnergyFlowAction,
} from '@store/types';

export const initialGatewayStatus: CustomerGatewayHealthResponseModel = {
    count: 0,
    list: [],
};

export const initialState: EnergyState = {
    error: false,
    loading: true,

    status: initialGatewayStatus,
    deviceId: '',
    provider: '',

    pvMaxPower: 0,
    pvPeakPower: 0,
    pvEfficiency: 0,
    pvConfigurationId: '',

    historicalConsumption: 0,
    historicalPvGeneration: 0,
    historicalSelfSufficiency: 0,

    gcp: false,
    hasGCP: false,
    hasBattery: false,
    hasInverter: false,
    hasGasMeter: false,
    hasSolarCloud: false,
    hasSmartMeter: false,
    hasPvEfficiency: false,
    hasUkSmartMeter: false,
    hasElectricityMeter: false,
    hasReadingStartDate: false,

    solarCloudEndDate: null,
    solarCloudStartDate: null,

    meterDevices: null,
    gatewayDevice: null,
    pvBatteryDevices: null,
};

export default function reducer(
    state: EnergyState = initialState,
    action: EnergyAction | LiveDataAction | EnergyFlowAction,
): EnergyState {
    switch (action.type) {
        case EnergyActionTypes.ERROR:
        case EnergyActionTypes.LOADING:
        case EnergyActionTypes.SET_METER_DEVICES:
        case EnergyActionTypes.SET_GATEWAY_DEVICES:
        case EnergyActionTypes.SET_PVBATTERY_DEVICES:
            return {
                ...state,
                ...action.payload,
            };
        case EnergyActionTypes.STATUS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: false,
            };
        case EnergyFlowActionTypes.PVBATTERY_STATUS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: false,
            };
        case EnergyActionTypes.SET_PV_PEAK: {
            return {
                ...state,
                pvPeakPower: action.payload.pvPeakPower ?? state.pvPeakPower,
            };
        }

        default:
            return state;
    }
}
