/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  IotPairingModel,
  IotPairingModifyModel,
  IotPairingVendorUrlModel,
  NotFound,
  PreconditionFailed,
  Unauthorized,
} from '../models';

export interface IoTAccountPairingDeletePairingRequest {
    vendor: string;
}

export interface IoTAccountPairingGetVendorConfigRequest {
    vendor: string;
    xOrigin?: string;
}

export interface IoTAccountPairingSetPairVendorRequest {
    iotPairingModifyModel: IotPairingModifyModel;
}

/**
 * 
 */
export class IoTAccountPairingApi extends runtime.BaseAPI {

    /**
     * Remove paired vendor account
     * Remove a linked vendor account
     */
    async ioTAccountPairingDeletePairingRaw(requestParameters: IoTAccountPairingDeletePairingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.vendor === null || requestParameters.vendor === undefined) {
            throw new runtime.RequiredError('vendor','Required parameter requestParameters.vendor was null or undefined when calling ioTAccountPairingDeletePairing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/smarthome/pairing:write"]);
        }

        const response = await this.request({
            path: `/api/v1/iot/pairing/{vendor}`.replace(`{${"vendor"}}`, encodeURIComponent(String(requestParameters.vendor))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove paired vendor account
     * Remove a linked vendor account
     */
    async ioTAccountPairingDeletePairing(requestParameters: IoTAccountPairingDeletePairingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ioTAccountPairingDeletePairingRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a list of supported 3rd party vendors.
     * Get linkable vendors
     */
    async ioTAccountPairingGetSupportedVendorsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IotPairingModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/smarthome/pairing:read"]);
        }

        const response = await this.request({
            path: `/api/v1/iot/vendors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Returns a list of supported 3rd party vendors.
     * Get linkable vendors
     */
    async ioTAccountPairingGetSupportedVendors(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IotPairingModel>> {
        const response = await this.ioTAccountPairingGetSupportedVendorsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the vendor specific authorization url for the OAuth2 code flow.
     * Get authorization url
     */
    async ioTAccountPairingGetVendorConfigRaw(requestParameters: IoTAccountPairingGetVendorConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IotPairingVendorUrlModel>> {
        if (requestParameters.vendor === null || requestParameters.vendor === undefined) {
            throw new runtime.RequiredError('vendor','Required parameter requestParameters.vendor was null or undefined when calling ioTAccountPairingGetVendorConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrigin !== undefined && requestParameters.xOrigin !== null) {
            headerParameters['x-origin'] = String(requestParameters.xOrigin);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/smarthome/pairing:read"]);
        }

        const response = await this.request({
            path: `/api/v1/iot/pairing/{vendor}`.replace(`{${"vendor"}}`, encodeURIComponent(String(requestParameters.vendor))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Returns the vendor specific authorization url for the OAuth2 code flow.
     * Get authorization url
     */
    async ioTAccountPairingGetVendorConfig(requestParameters: IoTAccountPairingGetVendorConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IotPairingVendorUrlModel> {
        const response = await this.ioTAccountPairingGetVendorConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pairs the specified vendor.
     * Link a vendor account
     */
    async ioTAccountPairingSetPairVendorRaw(requestParameters: IoTAccountPairingSetPairVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.iotPairingModifyModel === null || requestParameters.iotPairingModifyModel === undefined) {
            throw new runtime.RequiredError('iotPairingModifyModel','Required parameter requestParameters.iotPairingModifyModel was null or undefined when calling ioTAccountPairingSetPairVendor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/smarthome/pairing:write"]);
        }

        const response = await this.request({
            path: `/api/v1/iot/pairing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.iotPairingModifyModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Pairs the specified vendor.
     * Link a vendor account
     */
    async ioTAccountPairingSetPairVendor(requestParameters: IoTAccountPairingSetPairVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ioTAccountPairingSetPairVendorRaw(requestParameters, initOverrides);
    }

    /**
     * Synchronize devices for site
     */
    async ioTAccountPairingUpdateDevicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/smarthome/pairing:write"]);
        }

        const response = await this.request({
            path: `/api/v1/iot/vendors/devices`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Synchronize devices for site
     */
    async ioTAccountPairingUpdateDevices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ioTAccountPairingUpdateDevicesRaw(initOverrides);
    }

}
