import * as React from 'react';

import { Icon, UnknownFunction } from '@eon-home/react-library';

import CloseIcon from '@assets/icons/misc/close.svg';

import './index.scss';

interface Props {
    onClose: UnknownFunction;
}

export const CloseButton: React.FC<Readonly<Props>> = ({ onClose }: Props) => (
    <button className="c-close" onClick={onClose} data-testid="close-button">
        <Icon src={CloseIcon} />
    </button>
);

export default CloseButton;
