import { isDefined } from '@eon-home/react-library';

import { EventsResponseModel, HvacCommandData } from '@swagger-sse/api';

import { LiveDataEvent, LiveDataAction } from '@store/types';
import { SmartHomeDevice, LiveDataActionTypes } from '@store/enums';

export class HeatingCoolingEvent implements LiveDataEvent {
    private eventData: EventsResponseModel;
    private deviceType: SmartHomeDevice;

    constructor(data: EventsResponseModel, deviceType: SmartHomeDevice) {
        this.eventData = data;
        this.deviceType = deviceType;
    }

    public getAction(): LiveDataAction {
        const hvacData = this.eventData.data;

        if (!hvacData) {
            return {
                type: LiveDataActionTypes.HEATING_COOLING_EVENT,
                payload: null,
            };
        }

        const deviceId = this.eventData.deviceId;

        if (
            Object.values(HvacCommandData.StatusEnum).includes(hvacData.status)
        ) {
            return {
                type: LiveDataActionTypes.HEATING_COOLING_EVENT,
                payload: {
                    deviceId,
                    error: {
                        errorState: true,
                        text: [],
                        title: '',
                        code: 'command_failed',
                    },
                    loaded: true,
                },
            };
        }

        let state = hvacData.state;

        const power = hvacData.power;
        const temperature: Record<string, number> = {};

        const hasCelsius = isDefined(hvacData, 'temperature.celsius');
        const hasFahrenheit = isDefined(hvacData, 'temperature.fahrenheit');

        if (hasCelsius) {
            temperature.celsius = hvacData.temperature.celsius;
        }

        if (hasFahrenheit) {
            temperature.fahrenheit = hvacData.temperature.fahrenheit;
        }

        if (!hvacData.temperature || (!hasCelsius && !hasFahrenheit)) {
            state = 'OFFLINE';
        }

        const payload: any = {
            deviceId,
            deviceType: this.deviceType,
            power,
            active: true,
            metrics: {
                state,
                power,
                temperature,
            },
            loaded: true,
        };

        if (this.deviceType === SmartHomeDevice.AIR_CONDITIONING) {
            payload.fanSpeed = hvacData.fanSpeed;
            payload.swing = hvacData.swing;
            payload.mode = hvacData.mode;
        }

        return {
            type: LiveDataActionTypes.HEATING_COOLING_EVENT,
            payload,
        };
    }
}
