/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountChangeEmailModel,
  AdvancedAuthenticationRequestModel,
  AuthenticationRefreshRequestModel,
  AuthenticationResponseModel,
  Forbidden,
  StepUpRequiredResponseModel,
  Unauthorized,
  UriResponseModel,
} from '../models';

export interface AuthenticationChangeEmailRequest {
    accountChangeEmailModel?: AccountChangeEmailModel;
}

export interface AuthenticationLinkRequest {
    lang?: string;
}

export interface AuthenticationLoginRequest {
    authorization: string;
}

export interface AuthenticationLogoutRequest {
    authorization: string;
}

export interface AuthenticationOauthtokenRequest {
    advancedAuthenticationRequestModel: AdvancedAuthenticationRequestModel;
}

export interface AuthenticationTokenRequest {
    cookie?: string;
    authenticationRefreshRequestModel?: AuthenticationRefreshRequestModel;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * Change user email
     */
    async authenticationChangeEmailRaw(requestParameters: AuthenticationChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/auth/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.accountChangeEmailModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change user email
     */
    async authenticationChangeEmail(requestParameters: AuthenticationChangeEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authenticationChangeEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a prepared URL to be used for redirecting the user to the IDP login page.
     * Get IDP authorization url
     */
    async authenticationLinkRaw(requestParameters: AuthenticationLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UriResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/authorization-uri`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Returns a prepared URL to be used for redirecting the user to the IDP login page.
     * Get IDP authorization url
     */
    async authenticationLink(requestParameters: AuthenticationLinkRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UriResponseModel> {
        const response = await this.authenticationLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sign-In
     */
    async authenticationLoginRaw(requestParameters: AuthenticationLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticationResponseModel>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling authenticationLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/v1/auth/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sign-In
     */
    async authenticationLogin(requestParameters: AuthenticationLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticationResponseModel> {
        const response = await this.authenticationLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sign-Out
     */
    async authenticationLogoutRaw(requestParameters: AuthenticationLogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling authenticationLogout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/auth/sign-out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sign-Out
     */
    async authenticationLogout(requestParameters: AuthenticationLogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authenticationLogoutRaw(requestParameters, initOverrides);
    }

    /**
     * OAuth2-Token
     */
    async authenticationOauthtokenRaw(requestParameters: AuthenticationOauthtokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticationResponseModel>> {
        if (requestParameters.advancedAuthenticationRequestModel === null || requestParameters.advancedAuthenticationRequestModel === undefined) {
            throw new runtime.RequiredError('advancedAuthenticationRequestModel','Required parameter requestParameters.advancedAuthenticationRequestModel was null or undefined when calling authenticationOauthtoken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.advancedAuthenticationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * OAuth2-Token
     */
    async authenticationOauthtoken(requestParameters: AuthenticationOauthtokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticationResponseModel> {
        const response = await this.authenticationOauthtokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get new token
     */
    async authenticationTokenRaw(requestParameters: AuthenticationTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticationResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.cookie !== undefined && requestParameters.cookie !== null) {
            headerParameters['cookie'] = String(requestParameters.cookie);
        }

        const response = await this.request({
            path: `/api/v1/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.authenticationRefreshRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get new token
     */
    async authenticationToken(requestParameters: AuthenticationTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticationResponseModel> {
        const response = await this.authenticationTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
